import React from 'react'
import icon1 from '@src/assets/images/streamline/01.svg'
import icon2 from '@src/assets/images/streamline/02.svg'
import icon3 from '@src/assets/images/streamline/03.svg'
import icon4 from '@src/assets/images/streamline/04.svg'
import icon5 from '@src/assets/images/streamline/05.svg'
import icon6 from '@src/assets/images/streamline/06.svg'
import { useWindowDimensions } from '@src/utility/hooks/useWindowDimensions'
import { useTranslation } from 'react-i18next'

const Streamline = () => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const isMobile = width <= 1024
    const dataStreamLine = [
        {
            value: "Job posting on Mahajob",
            image: icon1,
            color: "#EFAF00"
        },
        {
            value: "Candidate selection",
            image: icon2,
            color: "#5BBED9"
        },
        {
            value: "Interview",
            image: icon3,
            color: "#07A537"
        },
        {
            value: "Visa application",
            image: icon4,
            color: "#333D7B"
        },
        {
            value: "Preparation for arrival and",
            image: icon5,
            color: "#EA5E8D"
        },
        {
            value: "Arrival and assignment",
            image: icon6,
            color: "#AE5AF4"
        },

    ]
    return (
        <div className='section-full twm-how-it-work-area'>
            <div className='bg-white custom-container rounded-lg p-2 flex flex-col items-center justify-center relative '>
                <div className={`font-bold ${isMobile ? 'text-20 ' : 'text-lg '}align-center`}>{t("Streamline Your Recruitment Process with Maha-Job")}</div>
                <div className={`font-thin ${isMobile ? 'text-small mt-1 text-center' : 'text-md align-center'}`}>{t("From attracting top talent to onboarding your new hire, Mahajob simplifies every step. Here's how it works:")}</div>
                <div className={`flex ${isMobile && 'flex-col gap-y-4 items center justify-center'} items-start justify-between mt-2 relative w-70 ${isMobile && 'w-full'}`}>
                    {
                        dataStreamLine.map((item, index) => (
                            <div key={index} className={`flex relative ${!isMobile ? 'flex-col w-40 items-center justify-center gap-y-3' : 'w-full items-center gap-y-2 justify-start gap-x-4'} `}>
                                <img className='h-20 w-32 img-stream' src={item.image} alt={item.value} />
                                <div
                                    style={{
                                        backgroundColor: item.color,
                                        padding: "4px",
                                        zIndex: 1
                                    }}
                                    className='text-white rounded-full flex items-center justify-center text-xs'>{`0${index + 1}`}
                                </div>
                                <div style={{
                                    textAlign: 'center'
                                }} className='text-small font-thin'>{t(`${item.value}`)}</div>
                            </div>
                        ))
                    }
                    <div style={{
                        borderTop: '2px solid rgba(0, 0, 0)',
                        borderLeft: isMobile ? '2px solid rgba(0, 0, 0)' : '',
                        bottom: isMobile ? 38 : 68,
                        width: isMobile ? '0.2rem' : width - (width * 0.430),
                        left: isMobile ? 136 : 65,
                        height: isMobile ? '30rem' : ''
                    }} className='text-black absolute'></div>
                </div>
            </div>
        </div>
    )
}

export default Streamline