import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"
import { loggedInfo } from "@src/helpers/checkAuth"

// TO-DO: untuk keperluan check role saat ini
const currentRole = () => {
  const { role } = loggedInfo()
  switch (role) {
    case "seeker":
      return "seeker"
    case "employer":
      return "employer"
    case "superadmin":
      return "admin"
    default:
      return undefined
  }
}

export const chatHandle = createApi({
  reducerPath: "chathandle",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["chathandle"],
  endpoints: (builder) => ({
    CreateRoom: builder.mutation({
      query: (roomId) => ({
        url: `${currentRole()}/chats`,
        method: "POST",
        headers: authHeader(),
        body: {
          subject: "",
          userId: roomId,
        },
      }),
      providesTags: ["CreateRoom"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    SendMessage: builder.mutation({
      query: (payload) => ({
        url: `${currentRole()}/chats/${payload?.chatId}/messages`,
        method: "POST",
        headers: authHeader(),
        body: {
          message: payload?.message,
        },
      }),
      providesTags: ["SendMessage"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    GetMessage: builder.mutation({
      query: (roomId) => ({
        url: `${currentRole()}/chats/${roomId}/messages`,
        method: "GET",
        headers: authHeader(),
      }),
      providesTags: ["GetMessage"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    GetMessage: builder.query({
      query: (roomId) => ({
        url: `${currentRole()}/chats/${roomId}/messages`,
        method: "GET",
        headers: authHeader(),
      }),
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
      forceRefetch: true,
    }),
    GetContactChat: builder.mutation({
      query: (page = 1, perPage = 100) => ({
        url: `${currentRole()}/chats?page=${page}&perPage=${perPage}`,
        method: "GET",
        headers: authHeader(),
      }),
      providesTags: ["GetContact"],
      transformResponse: (response) => response.results,
      transformErrorResponse: (response) => response,
    }),
    SendAttachment: builder.mutation({
      query: ({ chatId, file, message }) => {
        const formData = new FormData()
        formData.append("file", file)
        if (message) formData.append("message", message)

        return {
          url: `${currentRole()}/chats/${chatId}/messages`,
          method: "POST",
          headers: authHeader(),
          body: formData,
        }
      },
    }),

    GetAutoReply: builder.query({
      query: (params) => ({
        url: "/auto-replies",
        method: "GET",
        params,
        headers: authHeader(),
      }),
      providesTags: ["GetAutoReply"],
      transformResponse: (response) => response,
    }),
    getAllKeyword: builder.query({
      query: () => ({
        url: "/keywords",
        method: "GET",
        headers: authHeader(),
      }),
      transformResponse: (response) => response.data,
    }),
    postAutoReply: builder.mutation({
      query: (body) => ({
        url: "/auto-replies",
        method: "POST",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostMessage"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    patchAutoReply: builder.mutation({
      query: (body) => ({
        url: `/auto-replies/${body.id}`,
        method: "PATCH",
        headers: authHeader(),
        body: { ...body, id: undefined },
      }),
      providesTags: ["UpdateMessage"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    deleteAutoReply: builder.mutation({
      query: (body) => ({
        url: `/auto-replies/${body.id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      providesTags: ["UpdateMessage"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    postKeyword: builder.mutation({
      query: (body) => ({
        url: "/keywords",
        method: "POST",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostKeyword"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    requestAdminSession: builder.mutation({
      query: (body) => ({
        url: `${currentRole()}/chats/request-admin-session`,
        method: "POST",
        headers: authHeader(),
        body,
      }),
      providesTags: ["PostKeyword"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    patchKeyword: builder.mutation({
      query: (body) => ({
        url: `/keywords/${body.id}`,
        method: "PATCH",
        headers: authHeader(),
        body: { ...body, id: undefined },
      }),
      providesTags: ["UpdateKeyword"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    deleteKeyword: builder.mutation({
      query: (body) => ({
        url: `/keywords/${body.id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      providesTags: ["DeleteKeyword"],
      transformResponse: (response) => response,
      transformErrorResponse: (response) => response,
    }),
    switchChatReplier: builder.mutation({
      query: ({ chatId, ...body }) => ({
        url: `${currentRole()}/chats/${chatId}/replier`,
        method: "PATCH",
        headers: authHeader(),
        body,
      }),
    }),
  }),
})

export const {
  useCreateRoomMutation,
  useSendMessageMutation,
  useGetMessageMutation,
  useGetMessageQuery,
  useGetContactChatMutation,
  useSendAttachmentMutation,
  useGetAutoReplyQuery,
  useGetAllKeywordQuery,
  usePostAutoReplyMutation,
  usePatchAutoReplyMutation,
  useDeleteAutoReplyMutation,
  usePostKeywordMutation,
  usePatchKeywordMutation,
  useDeleteKeywordMutation,
  useSwitchChatReplierMutation,
  useRequestAdminSessionMutation,
} = chatHandle
