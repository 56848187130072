import toast from "react-hot-toast"
import moment from "moment"
import Message from "@src/components/chat/Message"
import classNames from "classnames"
import useIsSafari from "@src/utility/hooks/useIsSafari"
import EmptyMessage from "@src/assets/images/chat/empty_message.jpg"
import Notification from "@src/components/chat/Notification"
import FloatingButton from "@src/components/chat/FloatingButton"
import NotificationSound from "@src/assets/sounds/notification-sound.aac"
import { Mail } from "react-feather"
import { loggedInfo } from "@src/helpers/checkAuth"
import { useLocation } from "react-router-dom"
import { SocketContext } from "@src/utility/context/Socket"
import { useMediaQuery } from "usehooks-ts"
import { Button, Col, Row } from "reactstrap"
import {
  useGetMessageQuery,
  useRequestAdminSessionMutation,
  useSwitchChatReplierMutation,
} from "@src/redux/chat/chatHandle"
import { hideChat, onActiveChat } from "@store/chat/chatActions"
import { setParamChatPagination } from "@src/redux/chat/listChatPagination"
import { getPhoto, renameContact } from "@src/utility/Utils"
import { useSelector, useDispatch } from "react-redux"
import { useGetSubscriptionsMutation } from "@src/redux/seeker/seekerSubscription"
import { FooterBody, HeaderBody, HeaderPeople, ListPeople } from "@src/components"
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"

export const ChatProvider = () => {
  const audioPlayer = useRef(null)
  /** @type {ReturnType<typeof useRef<HTMLDivElement|null>>} */
  const desktopChatMessagesAnchor = useRef(null)

  /** @type {import("socket.io-client").Socket} */
  const socket = useContext(SocketContext)
  const location = useLocation()
  const md = useMediaQuery("(max-width: 875px)")
  const isSafari = useIsSafari()
  const dispatch = useDispatch()
  const { token, role, email } = loggedInfo()

  const listChatPagination = useSelector((state) => state.listChatPagination)
  const { isOpen, activeChat } = useSelector((state) => state.chatAction.value)

  const {
    data: dataHistory,
    refetch,
    isLoading: isLoadingHistory,
  } = useGetMessageQuery(activeChat, {
    refetchOnMountOrArgChange: true,
  })
  const [switchChatReplier, { isLoading: isSwitchingChatReplier }] = useSwitchChatReplierMutation()
  const [requestAdminSession, { isLoading: isRequestingAdminSession }] =
    useRequestAdminSessionMutation()

  const [dataMessage, setDataMessage] = useState([])
  const [dataAllPeople, setDataAllPeople] = useState([])
  const [selectedContact, setSelectedContact] = useState(null)
  const [onlineUsers, setOnlineUsers] = useState([])

  const historyData = useMemo(() => {
    return dataHistory
  }, [dataHistory])

  const handleRequestAdminSession = () => {
    if (historyData) {
      requestAdminSession({ chatId: activeChat }).then(() => {
        refetch().then(() => socket.emit("messageOpened"))
      })
    }
  }

  const handleSwitchChatReplier = () => {
    if (historyData) {
      switchChatReplier({
        chatId: activeChat,
        chatReplier: historyData.chatReplier === "admin" ? "bot" : "admin",
      }).then(() => refetch(activeChat).then(() => socket.emit("messageOpened")))
    }
  }

  const playAudio = useCallback(() => {
    try {
      if (!isSafari) {
        audioPlayer.current?.play()
        audioElement.play()
      }
    } catch (error) {
      console.log("play sound err: ", error)
    }
  }, [audioPlayer])

  const ListContact = useMemo(() => {
    const resultData = dataAllPeople || []
    return resultData
  }, [dataAllPeople, listChatPagination])

  const detailActive = useMemo(() => {
    let data = activeChat
      ? dataAllPeople?.filter((i) => i.id === activeChat)
      : dataAllPeople.length > 0
      ? dataAllPeople[0]
      : []
    data = data.length > 0 ? data[0] : {}

    return {
      firstName:
        data?.members?.length > 0
          ? data?.members[0]?.company?.name || data?.members[0]?.firstName
          : "-",
      id: data?.members?.length > 0 ? data?.members[0]?.id : "-",
      photo: data?.members?.length > 0 ? data?.members[0]?.photo : null,
    }
  }, [dataAllPeople, activeChat])

  const messages = useMemo(() => {
    const tempArr = dataMessage?.slice() || []

    if (tempArr.length > 0) {
      tempArr.sort((a, b) => moment(a.createdAt).format("x") - moment(b.createdAt).format("x"))
    }

    return tempArr
  }, [dataMessage])

  const receiverId = useMemo(() => {
    const dataReceiver = ListContact || []
    const item = dataReceiver
      .filter((o) => o.id === activeChat)
      .map((itm) => {
        return itm?.members[0]?.id || null
      })
    return item.length > 0 ? item[0] : null
  }, [ListContact, activeChat])

  const isReceiverOnline = useMemo(() => {
    const data = onlineUsers || []
    return data.some((o) => o.id === receiverId)
  }, [onlineUsers, receiverId])

  const newMessageCounts = useMemo(() => {
    return dataAllPeople.filter((o) => o.isHasNewMessage === true).length
  }, [dataAllPeople])

  const addMessage = useCallback(
    (message) => {
      if (!isOpen) {
        toast(message?.message || "", {
          position: "top-center",
          icon: <Mail />,
        })
      }
      if (isOpen) {
        refetch().then(() => socket.emit("messageOpened"))
      }
      playAudio()
    },
    [activeChat, isOpen]
  )

  const [getSubscriptions, { isLoading }] = useGetSubscriptionsMutation()

  useEffect(
    () => desktopChatMessagesAnchor.current?.scrollIntoView({ behavior: "instant" }),
    [messages]
  )

  useEffect(() => {
    socket.on("getMessage", addMessage)
    return () => socket.off("getMessage", addMessage)
  }, [addMessage])

  useEffect(() => {
    if (role === "seeker") getSubscriptions()

    socket.on("connect", () => {
      console.log(socket.connected ? "connected" : "disconnect")
    })

    socket.on("listChats", (data = []) => setDataAllPeople(data))
    socket.on("getOnlineUsers", (data = []) => setOnlineUsers(data))

    // handling error
    socket.on("disconnect", (reason) => {
      console.log("disconnect")
      if (reason === "io server disconnect") {
        socket.connect()
      }
    })

    socket.on("connect_error", () => console.log("connect_error..."))
    socket.io.on("error", () => console.log("error: "))

    return () => {
      socket.off("connect")
      socket.off("listChats")
      socket.off("disconnect")
      socket.off("connect_error")
      socket.off("getOnlineUsers")
      socket.off("messageOpened")
    }
  }, [])

  useEffect(() => setDataMessage(historyData?.chatMessages || []), [dataHistory, activeChat])

  useEffect(() => {
    if (ListContact && ListContact.length > 0 && !activeChat) {
      const roomId = ListContact[0].id
      dispatch(onActiveChat(roomId))
    }
  }, [ListContact, activeChat])

  useEffect(() => {
    // selalu get data ketika ada perubahan active contact
    if (activeChat && isOpen) {
      refetch().then(() => {
        socket.emit("messageOpened")
      })
    }
  }, [activeChat, isOpen])

  useEffect(() => {
    // berfungsi sbg perubahan pada component
    if (!token) {
      dispatch(hideChat())
      dispatch(onActiveChat(null))
      socket.disconnect()
    }

    // reconnect socket on reload
    if (token && socket.disconnected) socket.connect()

    return () => socket.close()
  }, [location, token, socket])

  const onCloseChat = () => {
    dispatch(hideChat())
  }

  const onClickContact = useCallback(
    (e) => {
      const roomId = e.id
      setSelectedContact(roomId)
      if (activeChat !== roomId) {
        dispatch(onActiveChat(roomId))
        refetch().then(() => {
          socket.emit("messageOpened")
        })
      }
    },
    [activeChat]
  )

  const _onMessage = () => {
    refetch()
    console.log("refetch")
  }

  const onSearchPeople = (name) => {
    dispatch(setParamChatPagination({ search: name, limit: 10 }))
    socket.emit("listChatsPagination", { search: name })
  }

  const isConversatingWithAdmin = historyData?.subject === "admin chats"
  const isAdminSessionRequested = !!historyData?.requestedAdminAt
  const isAdminOnline = !!onlineUsers.find((ou) => ou.email === process.env.REACT_APP_ADMIN_EMAIL)
  const isAdminSessionActive = historyData?.chatReplier === "admin"
  const isAdmin = role === "superadmin"

  return (
    <div>
      <audio ref={audioPlayer} src={NotificationSound} />
      <div
        className={classNames(
          "body-chat shadow-lg rounded-3 border position-fixed bottom-0 end-0 mb-2 mx-2 bg-white px-2 py-0 pt-2",
          { "w-90": !!md, "w-50": !md }
        )}
        style={{
          display: isOpen ? "block" : "none",
          transform: `scale(${isOpen ? 1 : 0})`,
          zIndex: 99,
        }}
      >
        {md ? (
          <Row>
            {!selectedContact && (
              <Col xs={100} className="d-flex flex-column px-0" style={{ height: "450px" }}>
                <HeaderPeople onChangeText={onSearchPeople} onClick={onCloseChat} isMobile={true} />
                <ListPeople data={ListContact} idActive={activeChat} onClick={onClickContact} />
              </Col>
            )}
            {selectedContact && (
              <Col xs={12} className="px-0">
                <HeaderBody
                  isMobile={true}
                  onClick={onCloseChat}
                  name={renameContact(detailActive?.firstName)}
                  imgUrl={getPhoto(detailActive?.photo)}
                  status={isReceiverOnline}
                  onBackClick={() => setSelectedContact(null)}
                  showStopSessionButton={isAdmin && historyData?.chatReplier === "admin"}
                  onStopSessionButtonClick={handleSwitchChatReplier}
                />
                {/* Message Room */}
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    overflowY: "auto",
                    order: 2,
                    flexDirection: "column-reverse",
                    flexFlow: 1,
                    height: "22em",
                    scrollbarColor: "rgba(255,255,255, .16)",
                    scrollbarWidth: "thin",
                    verticalAlign: "baseline",
                    scrollBehavior: "smooth",
                  }}
                >
                  <ol className="py-1 ul-message z-1">
                    {!isLoadingHistory && messages.length > 0
                      ? messages.map((message, index) => {
                          const isLast = index + 1 === messages.length
                          const isSystemNotification = message.sender === "system"

                          if (isSystemNotification) {
                            return (
                              <Fragment key={message.id + index}>
                                <Notification message={message} />
                                {!isAdmin &&
                                  !isAdminSessionActive &&
                                  isAdminOnline &&
                                  isLast &&
                                  !isAdminSessionRequested &&
                                  isConversatingWithAdmin && (
                                    <div
                                      className="px-1"
                                      style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                    >
                                      <div
                                        className="p-1 bg-light rounded lh-1"
                                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                      >
                                        <span
                                          className="d-block text-xs"
                                          style={{ marginBottom: "5px" }}
                                        >
                                          Punya pertanyaan lebih lanjut?
                                        </span>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          onClick={handleRequestAdminSession}
                                          disabled={isRequestingAdminSession}
                                        >
                                          Hubungi admin
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                {isLast && <div ref={desktopChatMessagesAnchor} />}
                              </Fragment>
                            )
                          }

                          return (
                            <Fragment key={message.id + index}>
                              <Message
                                key={message.id + index}
                                message={message}
                                isSelfOwned={email === message.user?.email}
                              />
                              {!isAdmin &&
                                !isAdminSessionActive &&
                                isAdminOnline &&
                                isLast &&
                                !isAdminSessionRequested &&
                                isConversatingWithAdmin && (
                                  <div
                                    className="px-1"
                                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                  >
                                    <div
                                      className="p-1 bg-light rounded lh-1"
                                      style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                    >
                                      <span
                                        className="d-block text-xs"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Punya pertanyaan lebih lanjut?
                                      </span>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={handleRequestAdminSession}
                                        disabled={isRequestingAdminSession}
                                      >
                                        Hubungi admin
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              {isLast && <div ref={desktopChatMessagesAnchor} />}
                            </Fragment>
                          )
                        })
                      : !isLoadingHistory && (
                          <div className="my-2 w-100 text-center">
                            <img src={EmptyMessage} width={152} />
                            <p className="text-secondary mt-1">Belum ada pesan.</p>
                          </div>
                        )}
                  </ol>
                </div>

                {/* Footer Room */}
                {role !== "superadmin" || historyData?.chatReplier === "admin" ? (
                  <FooterBody
                    roomId={activeChat}
                    receiverId={receiverId}
                    placeholder="Tulis pesan..."
                    onMessage={_onMessage}
                  />
                ) : (
                  <div className="flex flex-column align-items-center bg-white p-1 shadow">
                    <Button
                      color="primary"
                      onClick={handleSwitchChatReplier}
                      disabled={isSwitchingChatReplier}
                    >
                      Mulai sesi bersama admin
                    </Button>
                  </div>
                )}
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col xs={4} className="d-flex flex-column border-end px-0">
              <HeaderPeople onChangeText={onSearchPeople} />
              <ListPeople data={ListContact} idActive={activeChat} onClick={onClickContact} />
            </Col>
            <Col className="px-0 flex flex-column">
              <HeaderBody
                onClick={onCloseChat}
                name={detailActive?.firstName}
                imgUrl={getPhoto(detailActive?.photo)}
                status={isReceiverOnline}
                showStopSessionButton={isAdmin && historyData?.chatReplier === "admin"}
                onStopSessionButtonClick={handleSwitchChatReplier}
              />
              {/* Message Room */}
              <div style={{ height: 0, flexGrow: 1, overflowY: "auto" }}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    overflowY: "auto",
                    order: 1,
                    flexDirection: "column-reverse",
                    flexFlow: 1,
                    scrollbarColor: "rgba(255,255,255, .16)",
                    scrollbarWidth: "thin",
                    verticalAlign: "baseline",
                    scrollBehavior: "smooth",
                  }}
                >
                  <ol className="py-1 ul-message z-1">
                    {!isLoadingHistory && messages.length > 0
                      ? messages.map((message, index) => {
                          const isLast = index + 1 === messages.length
                          const isSystemNotification = message.sender === "system"

                          if (isSystemNotification) {
                            return (
                              <Fragment key={message.id + index}>
                                <Notification message={message} />
                                {!isAdmin &&
                                  !isAdminSessionActive &&
                                  isAdminOnline &&
                                  isLast &&
                                  !isAdminSessionRequested &&
                                  isConversatingWithAdmin && (
                                    <div
                                      className="px-1"
                                      style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                    >
                                      <div
                                        className="p-1 bg-light rounded lh-1"
                                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                      >
                                        <span
                                          className="d-block text-xs"
                                          style={{ marginBottom: "5px" }}
                                        >
                                          Punya pertanyaan lebih lanjut?
                                        </span>
                                        <Button
                                          size="sm"
                                          color="primary"
                                          onClick={handleRequestAdminSession}
                                          disabled={isRequestingAdminSession}
                                        >
                                          Hubungi admin
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                {isLast && <div ref={desktopChatMessagesAnchor} />}
                              </Fragment>
                            )
                          }

                          return (
                            <Fragment key={message.id + index}>
                              <Message
                                message={message}
                                isSelfOwned={email === message.user?.email}
                              />
                              {!isAdmin &&
                                !isAdminSessionActive &&
                                isAdminOnline &&
                                isLast &&
                                !isAdminSessionRequested &&
                                isConversatingWithAdmin && (
                                  <div
                                    className="px-1"
                                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                  >
                                    <div
                                      className="p-1 bg-light rounded lh-1"
                                      style={{ paddingTop: "5px", paddingBottom: "5px" }}
                                    >
                                      <span
                                        className="d-block text-xs"
                                        style={{ marginBottom: "5px" }}
                                      >
                                        Punya pertanyaan lebih lanjut?
                                      </span>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={handleRequestAdminSession}
                                        disabled={isRequestingAdminSession}
                                      >
                                        Hubungi admin
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              {isLast && <div ref={desktopChatMessagesAnchor} />}
                            </Fragment>
                          )
                        })
                      : !isLoadingHistory && (
                          <div className="my-2 w-100 text-center">
                            <img src={EmptyMessage} width={152} />
                            <p className="text-secondary mt-1">Belum ada pesan.</p>
                          </div>
                        )}
                  </ol>
                </div>
              </div>

              {/* Footer Room */}
              {role !== "superadmin" || historyData?.chatReplier === "admin" ? (
                <FooterBody
                  roomId={activeChat}
                  receiverId={receiverId}
                  placeholder="Tulis pesan..."
                  onMessage={_onMessage}
                />
              ) : (
                <div className="flex flex-column align-items-center bg-white p-1 shadow">
                  <Button
                    color="primary"
                    onClick={handleSwitchChatReplier}
                    disabled={isSwitchingChatReplier}
                  >
                    Mulai sesi bersama admin
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>

      {/* Button Floating */}
      {!isOpen && !isLoading && <FloatingButton newMessageCounts={newMessageCounts} />}
    </div>
  )
}
