import Head from "@src/components/head"
import NavigationBar from "@src/views/shared/NavigationBar"
import logo from "@src/assets/images/logo/logo.png"
import { footer as footerData, navbar as navigations } from "@src/navigation/landing-employer"
import "./index.scss"
import React from "react"
import Banner from "./components/Banner"
import StatsV2 from "./components/StatsV2"
import Streamline from "./components/Streamline"
import Features from "./components/Features"
import Questions from "./components/Questions"
import Footer from "@src/views/shared/Footer"

const LandingEmployerV2 = () => {
  return (
    <div className="page-wrapper bg-gray-200 section-background">
      <Head
        title="MahaJob | 採用情報 - インドネシア人材採用"
        description="インドネシアの優秀な人材を採用しませんか？ MahaJobは日本企業向けに最適な人材を提供します。"
        keywords="インドネシア, 人材, 採用, インドネシア人材, インドネシア採用, MahaJob, Lowongan kerja jepang, Lowongan kerja di Jepang untuk pria, situs lowongan kerja jepang, lowongan kerja jepang untuk lulusan sma, lowongan kerja jepang untuk lulusan smk, lowongan kerja jepang untuk lulusan d3, lowongan kerja jepang untuk lulusan s1, lowongan kerja jepang untuk lulusan s2, lowongan kerja jepang untuk lulusan s3, lowongan kerja jepang untuk lulusan fresh graduate, lowongan kerja ke jepang Gratis 2025"
        type="website"
        siteName="MahaJob"
        image={logo}
        canonical="https://maha-job.com/employer"
      />
      <NavigationBar page={"employer"} navigations={navigations} isNew={true} />
      <Banner />
      <StatsV2 />
      <Streamline />
      <Features />
      <Questions />
      <Footer navigations={footerData("employer")} />
    </div>
  )
}

export default LandingEmployerV2
