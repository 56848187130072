const Notification = ({ message }) => {
  return (
    <div className="flex justify-center px-1" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
      <div
        className="px-1 bg-light rounded lh-1 text-center"
        style={{ paddingTop: "5px", paddingBottom: "5px" }}
      >
        <span className="text-xs">{message.message}</span>
      </div>
    </div>
  )
}

export default Notification
