import Avatar from "@src/assets/images/chat/avatar_chat.jpg"
import { Button } from "reactstrap"
import { renameContact } from "@src/utility/Utils"
import { ChevronLeft, Minimize2, StopCircle } from "react-feather"

export const HeaderBody = ({
  onClick,
  name,
  status = false,
  imgUrl,
  isMobile,
  onBackClick,
  showStopSessionButton,
  onStopSessionButtonClick,
}) => {
  return (
    <header className="header-chat-contact d-flex justify-content-between align-items-center py-1 z-3 px-1">
      <div className="d-flex align-items-center">
        <div className="d-flex gap-1 align-items-center">
          {isMobile && (
            <Button color="danger" size="sm" onClick={onBackClick}>
              <ChevronLeft size={16} />
            </Button>
          )}
          <img src={imgUrl || Avatar} className="rounded-circle profile-chat-pv" alt="profil" />
        </div>
        <div>
          <h5 className="mb-0">{renameContact(name)}</h5>
          <p className={`${status && "indicator-chat"} mb-0`} style={{}}>
            {status ? "Online" : "Offline"}
          </p>
        </div>
      </div>

      <div className="flex flex-wrap align-items-center justify-content-end" style={{ gap: "5px" }}>
        {showStopSessionButton && (
          <Button color="danger" size="sm" onClick={onStopSessionButtonClick}>
            <StopCircle size={16} />
          </Button>
        )}
        <div>
          <Button color="danger" size="sm" onClick={onClick}>
            <Minimize2 size={16} />
          </Button>
        </div>
      </div>
    </header>
  )
}
