import { lazy } from "react"
import { Navigate } from "react-router-dom"

import EmployerLayout from "@views/Employer/Dashboard"
import LandingEmployerV2 from "@src/views/Employer/LandingV2"
// import { withAuth } from "../../helpers/checkAuth"


const Register = lazy(() => import("@views/Employer/Register"))

const Profile = lazy(() => import("@views/Employer/Dashboard/Profile"))

const JobSeeker = lazy(() => import("@views/Employer/Dashboard/JobApplicants"))
const ChangePassword = lazy(() => import("@views/Employer/Dashboard/ChangePassword"))
const ManageJobs = lazy(() => import("@views/Employer/Dashboard/ManageJobs"))
const PostJob = lazy(() => import("@views/Employer/Dashboard/PostJob"))
const ManageIndustries = lazy(() => import("@views/Employer/Dashboard/ManageIndustries"))
const PostIndustries = lazy(() => import("@views/Employer/Dashboard/PostIndustries"))
const Resume = lazy(() => import("@views/Employer/Dashboard/Resume"))
const ContactUs = lazy(() => import("@views/ContactUs/ContactUsJp"))
const AboutUs = lazy(() => import("@views/AboutUs/AboutUsJp"))

// const DashboardEmployerLayout = withAuth(EmployerLayout)
const DashboardEmployerLayout = EmployerLayout

export default [
  {
    path: "",
    // element: <LandingEmployer />
    element: <LandingEmployerV2 />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "contact-us",
    element: <ContactUs />,
  },
  {
    path: "login",
    element: <Navigate to="/login" />,
  },
  {
    path: "register",
    meta: {
      layout: "protected",
    },
    element: <Register />,
  },
  {
    path: "dashboard",
    element: <DashboardEmployerLayout role="employer" />,
    children: [
      {
        path: "",
        element: <Navigate to={"/employer/dashboard/profile"} />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "manage-jobs",
        element: <ManageJobs />,
      },
      {
        path: "post-job",
        element: <PostJob />,
      },
      {
        path: "seekers",
        element: <JobSeeker />,
      },
      {
        path: "manage-industries",
        element: <ManageIndustries />,
      },
      {
        path: "post-industries",
        element: <PostIndustries />,
      },
      {
        path: "resume",
        element: <Resume />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
    ],
  },
]
