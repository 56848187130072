/* eslint-disable no-unused-vars */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { authHeader, baseUrlConfig } from '../../helpers/http'

const baseurl = 'job/apply'

export const jobApplyApi = createApi({
  reducerPath: 'job/apply',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['JobApply'],
  endpoints: (builder) => ({
    getJobApply: builder.mutation({
      query: (query) => ({
        url: `${baseurl}`,
        method: 'get',
        headers: authHeader(),
        params: query
      }),
      providesTags: ['GetJobApply'],
      transformResponse: (response) => response.results
    }),
    getJobApplyForAdmin: builder.mutation({
      query: () => ({
        url: `${baseurl}/admin`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: [`${baseurl}/admin`],
      transformResponse: (response) => response.results
    }),
    getJobApplyById: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'get'
      }),
      providesTags: ['GetJobApply'],
      transformResponse: (response) => response.results
    }),
    getAppliedJob: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/applied/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAppliedJob'],
      transformResponse: (response) => response.results
    }),
    getApplicant: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/applicant/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetApplicant'],
      transformResponse: (response) => response.results
    }),
    postJobApply: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'post',
        headers: authHeader()
      }),
      providesTags: ['PostJobApply'],
      transformResponse: (response) => response.results
    }),
    updateJobApply: builder.mutation({
      query(data) {
        const { id, body } = data
        return {
          url: `${baseurl}/${id}`,
          method: 'put',
          headers: {
            ...authHeader(),
            "Content-Type": "application/json"
          },
          params: body
        }
      },
      providesTags: ['UpdateJobApply'],
      transformResponse: (response) => response.results
    }),
    updateJobApplyStatus: builder.mutation({
      query(data) {
        const { id, body } = data
        return {
          url: `${baseurl}/status/${id}`,
          method: 'put',
          headers: {
            ...authHeader(),
            "Content-Type": "application/json"
          },
          params: {status: body.status},
          body: {note: body.note}
        }
      },
      providesTags: ['UpdateJobApply'],
      transformResponse: (response) => response.results
    }),
    deleteJobApply: builder.mutation({
      query: (id) => ({
        url: `${baseurl}/${id}`,
        method: 'delete',
        headers: authHeader()
      }),
      providesTags: ['DeleteJobApply'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetJobApplyMutation,
  useGetJobApplyForAdminMutation,
  useGetApplicantMutation,
  useGetJobApplyBySlugMutation,
  usePostJobApplyMutation,
  useGetAppliedJobMutation,
  useUpdateJobApplyStatusMutation,
  useUpdateJobApplyMutation,
  useDeleteJobApplyMutation
} = jobApplyApi
