import Swal from "sweetalert2"
import { Button } from "reactstrap"
import { showChat } from "@src/redux/chat/chatActions"
import { loggedInfo } from "@src/helpers/checkAuth"
import { useCallback } from "react"
import { MessageSquare } from "react-feather"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useGetSubscriptionsMutation } from "@src/redux/seeker/seekerSubscription"

/** @type {React.FC<{ newMessageCounts: number }>} */
const FloatingButton = ({ newMessageCounts }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { activeChat } = useSelector((state) => state.chatAction.value)
  const { token, role } = loggedInfo()

  const [getSubscriptions] = useGetSubscriptionsMutation()

  const onOpenChat = useCallback(async () => {
    if (!token) {
      return Swal.fire({
        icon: "error",
        title: "Can't Access fitur chat",
        text: "Please login to access this feature!",
        confirmButtonText: "Login Now",
        showCloseButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) navigate(`/login?page=${pathname}`, { replace: true })
      })
    }

    if (role === "seeker") {
      const { data } = await getSubscriptions()
      const isSubscribed =
        data.length > 0 ? data.some(({ status }) => status === "activated") : false

      if (!isSubscribed) {
        return Swal.fire({
          icon: "error",
          title: "Please Subscribe First",
          text: "Please Subscribe to access this feature!",
          confirmButtonText: "Subscribe Now",
          showCloseButton: true,
        }).then(({ isConfirmed }) => {
          if (isConfirmed) navigate("/subscription", { replace: true })
        })
      }
    }

    dispatch(showChat(activeChat))
  }, [activeChat, token, role, pathname, navigate, dispatch])

  return (
    <Button
      onClick={onOpenChat}
      color="primary"
      className="btn-chat-float shadow-lg round-5 rounded-pill position-fixed bottom-0 end-0 bg-white"
      style={{ zIndex: 99 }}
    >
      <MessageSquare />
      &nbsp;&nbsp;Chat
      {newMessageCounts > 0 && (
        <div className="dot-new-msg">
          <span>{newMessageCounts}</span>
        </div>
      )}
    </Button>
  )
}

export default FloatingButton
