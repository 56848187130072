import { useEffect, useState } from "react"
import Select from "react-select"

import DataTable from "react-data-table-component"
import { Edit2, Trash2 } from "react-feather"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

import toast from "react-hot-toast"
import ModalDialog from "../../../shared/modals/ModalDialog"
import { paginationRowsPerPageOptions } from "../../../../utility/pagination.options"
import { useSkin } from "@src/utility/hooks/useSkin"
import {
  useDeleteKeywordMutation,
  useGetAllKeywordQuery,
  usePatchKeywordMutation,
  usePostKeywordMutation,
} from "@src/redux/chat/chatHandle"
import { Controller, useForm } from "react-hook-form"

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
    width: "6%",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.key,
    style: { whiteSpace: "unset" },
    sortable: true,
    width: "80%",
  },
  {
    name: "Modify",
    selector: (row) => row.modify,
  },
]

function AddModal({ onClose, onComplete }) {
  const [postKeyword] = usePostKeywordMutation()
  const { control, handleSubmit } = useForm()

  const onSubmit = (data, e) => {
    e.preventDefault()
    const payload = {
      key: data.keyword,
    }
    postKeyword(payload)
      .then(() => {
        onComplete()
      })
      .catch((err) => {
        toast.error(`Failed save data:  ${err?.response?.data}`, { position: "top-center" })
      })
  }

  return (
    <ModalDialog open={true} setOpen={onClose} title="" disableCloseButton>
      <ModalHeader>Add Keyword</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>keyword</Label>
            <Controller
              name="keyword"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} required name="name" className="form-control" />
              )}
            />
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>
    </ModalDialog>
  )
}

function UpdateModal({ open, setOpen, data, onSuccess }) {
  // eslint-disable-next-line no-unused-vars
  const [patchKeyword] = usePatchKeywordMutation()
  const { control, handleSubmit, setValue } = useForm()

  const onSubmit = (formValues) => {
    const payload = {
      id: data.id,
      key: formValues.keyword,
    }
    patchKeyword(payload)
      .then((res) => {
        if (res.error) {
          return toast.error(`Failed save data:  ${res?.error?.error}`, { position: "top-center" })
        }
        toast.success(`Success save data`, { position: "top-center", duration: 5000 })
        onSuccess()
        setOpen(false)
      })
      .catch((err) => {
        toast.error(`Failed save data:  ${err?.response?.data}`, { position: "top-center" })
      })
  }

  useEffect(() => {
    if (data) {
      setValue("keyword", data.key)
    }
  }, [data])

  return (
    <ModalDialog open={open} setOpen={setOpen} title="" disableCloseButton>
      <ModalHeader>Edit Keyword</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Message</Label>
            <Controller
              name="keyword"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} required name="name" className="form-control" />
              )}
            />
          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>
    </ModalDialog>
  )
}

function DeleteModal({ open, setOpen, id, onSuccess }) {
  const [delCat] = useDeleteKeywordMutation()

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title="Delete"
      onClose={() => {
        delCat({ id }).then((res) => {
          if (res.error) {
            return toast.error(`Failed delete data:  ${res?.error?.error}`, {
              position: "top-center",
            })
          }
          toast.success(`Success delete data`, { position: "top-center", duration: 5000 })
          onSuccess()
          setOpen(false)
        })
      }}
      closeText="Delete"
      buttonColor="danger"
    >
      Are you sure to delete this keyword?
    </ModalDialog>
  )
}

function ModifyButtonGroup({ data, onSuccess, keywordData }) {
  const [upOpen, setUpOpen] = useState(false)
  const [delOpen, setDelOpen] = useState(false)

  const edit = () => {
    setUpOpen(true)
  }

  const deleteCat = () => {
    setDelOpen(true)
  }
  return (
    <>
      <DeleteModal id={data.id} open={delOpen} setOpen={setDelOpen} onSuccess={onSuccess} />
      <UpdateModal
        keywordData={keywordData}
        data={data}
        open={upOpen}
        setOpen={setUpOpen}
        onSuccess={onSuccess}
      />
      <ButtonGroup>
        <UncontrolledTooltip placement="top" target={`edit-${data.id}`}>
          Edit
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon"
          color="primary"
          id={`edit-${data.id}`}
          onClick={() => edit()}
        >
          <Edit2 size={16} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target={`delete-${data.id}`}>
          Delete
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon"
          color="danger"
          id={`delete-${data.id}`}
          onClick={() => deleteCat()}
        >
          <Trash2 size={16} />
        </Button.Ripple>
      </ButtonGroup>
    </>
  )
}

export default function Keywords() {
  const { skin } = useSkin()

  const [tableData, setTableData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [limit, setLimit] = useState(10)
  const [totalPage] = useState()
  const [page, setPage] = useState(1)

  const { data, isLoading, isError, refetch } = useGetAllKeywordQuery({ page, limit })

  const handleComplete = () => {
    setDialogOpen(false)
    refetch()
    toast.success(`Success save data`, { position: "top-center", duration: 5000 })
  }

  useEffect(() => {
    if (data) {
      const row = []
      Array.from(data).forEach((item, index) => {
        row.push({
          id: index + 1,
          key: (
            <>
              <UncontrolledTooltip placement="top" target={`key-${index}`}>
                {item.key}
              </UncontrolledTooltip>
              <span id={`key-${index}`}>{item.key}</span>
            </>
          ),
          modify: (
            <div className="d-flex ">
              <ModifyButtonGroup data={item} isActive={item.isActive} onSuccess={() => refetch()} />
            </div>
          ),
        })
      })
      setTableData(row)
    }
  }, [data])

  return (
    <>
      <Modal
        className="modal-sm"
        centered
        isOpen={dialogOpen}
        toggle={() => {
          setDialogOpen(!dialogOpen)
        }}
      >
        <AddModal onComplete={handleComplete} onClose={() => setDialogOpen(false)} />
      </Modal>
      <Card>
        <CardHeader>
          <Row sm={1} md={2} lg={2} className="w-100">
            <Col>
              <CardTitle>Auto Reply</CardTitle>
            </Col>
            <Col className="text-right">
              <Button
                type="button"
                color="outline-primary"
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                + New
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {isError ? (
            <>Error: {JSON.stringify(error)}</>
          ) : isLoading ? (
            <>...Loading</>
          ) : data ? (
            <DataTable
              theme={skin}
              columns={columns}
              data={tableData}
              responsive={true}
              pagination
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              paginationServer
              onChangeRowsPerPage={(rows) => {
                setLimit(rows)
              }}
              onChangePage={(page) => {
                setPage(page)
              }}
              paginationTotalRows={totalPage}
              className={"text-center"}
            />
          ) : null}
        </CardBody>
      </Card>
    </>
  )
}
