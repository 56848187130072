import constants from "@src/utility/constants"
import { getPhoto } from "@src/utility/Utils"
import moment from "moment"
import { useMemo } from "react"
import { Download } from "react-feather"
import BotAvatar from "../../assets/images/chat/bot_avatar.jpg"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Message({ message, isSelfOwned }) {
  const isBot = message.sender === "bot"

  const dateTime = useMemo(() => {
    const today = moment().format("DDMMYY")
    const currentDate = moment(message?.createdAt).format("DDMMYY")

    return currentDate === today
      ? moment(message?.createdAt).format("HH:MM A")
      : moment(message?.createdAt).format("DD/MM/YY HH:MM A")
  }, [message])

  return (
    <li
      className={classNames(
        isSelfOwned ? "justify-content-end" : "justify-content-start",
        "d-flex px-2"
      )}
    >
      <div className="item d-flex align-items-start">
        {!isSelfOwned && (
          <div className="flex-shrink-0">
            <img
              src={
                isBot
                  ? BotAvatar
                  : getPhoto(message?.user?.photo || message?.user?.seekerProfile?.photo)
              }
              className="rounded-circle profile-chat-msg"
              alt="profil"
            />
          </div>
        )}

        <div
          className="chat-message rounded "
          style={{ backgroundColor: isSelfOwned ? "#F1F1F1" : "#615EF0" }}
        >
          {message?.chatMessageAttachment && (
            <FileAndImageBubble {...message?.chatMessageAttachment} />
          )}
          <div className="d-flex align-items-center ">
            <pre
              className="block font-normal"
              style={{
                color: isSelfOwned ? "#444444" : "#fff",
                backgroundColor: "transparent",
                textWrap: "wrap",
                overflow: "hidden",
              }}
            >
              {message?.message}
            </pre>
          </div>
          <div className="flex items-center justify-between">
            <span
              style={{
                fontSize: 8,
                textAlign: isSelfOwned ? "right" : "left",
                color: isSelfOwned ? "#a6a5a2" : "#f5f5f5",
              }}
            >
              {dateTime}
            </span>
            {isBot && <span className="text-xs text-white">Oleh Bot</span>}
          </div>
        </div>

        {isSelfOwned && (
          <div className="flex-shrink-0">
            <img
              src={getPhoto(message?.user?.seekerProfile?.photo)}
              className="rounded-3 profile-chat-msg"
              alt="profil"
            />
          </div>
        )}
      </div>
    </li>
  )
}

const FileAndImageBubble = ({ type, url }) => {
  const fullUrl = `${constants.apiChatResourceURL}${url}`

  // const [downloadDocument] = useDownloadDocumentMutation()

  // const downloadAttachment = (filename) => {
  //   downloadDocument(url).then((res) => {
  //     const url = window.URL.createObjectURL(new Blob([res.data]))
  //     const link = document.createElement("a")
  //     link.href = url
  //     link.setAttribute("download", filename)
  //     document.body.appendChild(link)
  //     link.click()
  //   })
  // }

  const handleClick = () => {
    if (type === "image") {
      // Open image in a new tab
      window.open(fullUrl, "_blank")
    } else if (type === "file") {
      // Open file in a new tab
      window.open(fullUrl, "_blank")
    }
  }

  return (
    <div className="flex items-center">
      <div className="cursor-pointer" onClick={handleClick}>
        {type === "image" ? (
          <img
            src={`${constants.apiChatResourceURL}${url}`}
            alt="Preview"
            className="mt-2 w-full h-40 object-cover rounded-lg"
          />
        ) : (
          <div className="mt-2 p-3 bg-gray-200 rounded-lg text-sm flex items-center gap-2">
            📄 File: {url.split("/").pop()}
          </div>
        )}
      </div>
      <button onClick={() => downloadAttachment()} className="h-5 w-5 p-1 border-0">
        <Download />
      </button>
    </div>
  )
}
