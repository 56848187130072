const useIsSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification))
  )
}

export default useIsSafari
