import AutoReply from "@src/views/Dashboard/pages/auto-reply/AutoReplay"
import Keywords from "@src/views/Dashboard/pages/keywords/Keywords"
import { lazy } from "react"
import { Navigate } from "react-router-dom"

const Home = lazy(() => import("@views/Dashboard/pages/Home"))
const Roles = lazy(() => import("@views/Dashboard/pages/roles"))
const JobPost = lazy(() => import("@views/Dashboard/pages/job-post/JobPost"))
const JobApplication = lazy(() => import("@views/Dashboard/pages/job-application/JobApplication"))
const DetailApplication = lazy(() => {
  return import("@views/Dashboard/pages/job-application/DetailApplication")
})
const Employer = lazy(() => import("@views/Dashboard/pages/employer/Employer"))
const JobSeeker = lazy(() => import("@views/Dashboard/pages/job-seeker/JobSeeker"))
const JobInterview = lazy(() => import("@views/Dashboard/pages/job-interview"))
const JobCategory = lazy(() => import("@views/Dashboard/pages/job-category/JobCategories"))
const JobIndustry = lazy(() => import("@views/Dashboard/pages/job-industry/JobIndustries"))
const JobType = lazy(() => import("@views/Dashboard/pages/job-type/JobTypes"))
const Administrator = lazy(() => import("@views/Dashboard/pages/administrator/Administrator"))
const SubscriptionPackage = lazy(() => import("@src/views/Dashboard/pages/subscriptionPackage"))
const Subscriber = lazy(() => import("@views/Dashboard/pages/subscriber"))
const JobSeekerCertification = lazy(() => {
  return import("@views/Dashboard/pages/job-seeker-certification/jobSeekerCertification")
})

const adminPath = [
  {
    path: "",
    element: <Navigate replace to="/dashboard/home" />,
  },
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "job-post",
    element: <JobPost />,
  },
  {
    path: "job-application",
    element: <JobApplication />,
  },
  {
    path: "job-application/:id",
    element: <DetailApplication />,
  },
  {
    path: "/dashboard/employer",
    element: <Employer />,
  },
  {
    path: "/dashboard/administrator",
    element: <Administrator />,
  },
  {
    path: "/dashboard/job-seeker",
    element: <JobSeeker />,
  },
  {
    path: "/dashboard/job-interview",
    element: <JobInterview />,
  },
  {
    path: "/dashboard/job-category",
    element: <JobCategory />,
  },
  {
    path: "/dashboard/keywords",
    element: <Keywords />,
  },
  {
    path: "/dashboard/auto-reply",
    element: <AutoReply />,
  },
  {
    path: "/dashboard/job-type",
    element: <JobType />,
  },
  {
    path: "/dashboard/job-industry",
    element: <JobIndustry />,
  },
  {
    path: "/dashboard/job-seeker-certification",
    element: <JobSeekerCertification />,
  },
  {
    path: "role-management",
    element: <Roles />,
  },
  {
    path: "/dashboard/subscribe/package",
    element: <SubscriptionPackage />,
  },
  {
    path: "/dashboard/subscriber",
    element: <Subscriber />,
  },
  {
    path: "login",
    element: <Navigate to="/login" />,
  },
]

export default adminPath
