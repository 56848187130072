import EmojiPicker from "emoji-picker-react"
import { SocketContext } from "@src/utility/context/Socket"
import { Button, Spinner } from "reactstrap"
import { setInitialMessage } from "@src/redux/chat/chatActions"
import { useDispatch, useSelector } from "react-redux"
import { Paperclip, Send, Smile, XCircle } from "react-feather"
import { useCallback, useContext, useEffect, useState } from "react"
import { useSendAttachmentMutation, useSendMessageMutation } from "@src/redux/chat/chatHandle"

export const FooterBody = ({ placeholder, roomId, senderId = "", receiverId, onSuccessSubmit }) => {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const { initialMessage } = useSelector((state) => state.chatAction.value)

  const [SendMessage, { data, isLoading, reset }] = useSendMessageMutation()
  const [sendAttachment, { data: dataSendAttachment, isLoading: isLoadingSendAttachment }] =
    useSendAttachmentMutation()

  const [msg, setMsg] = useState("")
  const [attachment, setAttachment] = useState()
  const [showEmoji, setShowEmoji] = useState(false)

  useEffect(() => {
    if (initialMessage) {
      setMsg(initialMessage)
      dispatch(setInitialMessage(""))
    }
  }, [initialMessage])

  useEffect(() => {
    if (!isLoading || dataSendAttachment || data || !isLoadingSendAttachment) {
      socket.emit("messageOpened")
      reset()
    }
  }, [receiverId, roomId, senderId, data, isLoading, isLoadingSendAttachment, dataSendAttachment])

  const _onClick = useCallback(() => {
    if (attachment?.data) {
      const payload = {
        chatId: roomId,
        file: attachment?.data,
        senderId,
        receiverId,
      }
      if (msg) {
        payload.message = JSON.stringify(msg)
      }

      return sendAttachment(payload).then(() => {
        setAttachment()
        setMsg("")
        const inputEl = document.getElementById("textInput")
        if (inputEl) inputEl.value = ""
        onSuccessSubmit?.()
      })
    }

    const payload = {
      chatId: roomId,
      senderId,
      receiverId,
      message: msg,
    }

    SendMessage(payload).then(() => {
      setMsg("")
      const inputEl = document.getElementById("textInput")
      if (inputEl) inputEl.value = ""
      onSuccessSubmit?.()
    })
  }, [msg, receiverId, roomId, senderId, attachment])

  const onChange = (e) => {
    setMsg(e.target.value)
    reset()
  }

  const uploadDocToChat = () => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"]
    const maxFileSize = 5 * 1024 * 1024

    const input = document.createElement("input")
    input.type = "file"
    input.onchange = (e) => {
      const file = e.target.files[0]
      if (!allowedTypes.includes(file.type)) {
        alert("Only file PDF, JPG, or PNG is allowed")
        return
      }
      if (file.size > maxFileSize) {
        alert("File size max 5MB!")
        return
      }

      if (file.type.startsWith("image/")) {
        const reader = new FileReader()
        reader.onload = () => setAttachment({ data: file, thumbnail: reader.result, type: "image" })
        reader.readAsDataURL(file)
      } else {
        setAttachment({ data: file, type: "document" })
      }
    }
    input.click()
  }

  return (
    <div className="relative">
      {attachment && attachment.type === "image" && (
        <div className="position-absolute shadow w-full -top-70 p-1 bg-white">
          <img
            src={attachment?.thumbnail}
            className="w-10 object-cover text-center rounded-lg"
            alt="preview"
          />
          <XCircle className="position-absolute" onClick={() => setAttachment()} />
        </div>
      )}
      {attachment && attachment.type === "document" && (
        <div className="position-absolute shadow w-full -top-50 p-1 bg-white">
          <span>{attachment?.data?.name}</span>
          <XCircle className="position-absolute" onClick={() => setAttachment()} />
        </div>
      )}
      <footer
        className="p-1 position-relative footer-chat flex flex-row"
        style={{
          order: 3,
        }}
      >
        <div className="flex">
          {showEmoji && (
            <EmojiPicker
              height={"300px"}
              width={"300px"}
              searchDisabled
              defaultSkinTone=""
              className="position-absolute -top-300"
              onEmojiClick={(e) => {
                setMsg((p) => p + e.emoji)
                setShowEmoji(false)
              }}
            />
          )}

          <Button className="p-0" onClick={() => setShowEmoji((p) => !p)} color="link">
            <Smile />
          </Button>
          <Button className="p-1" onClick={uploadDocToChat} color="link">
            <Paperclip />
          </Button>
        </div>
        <textarea
          id="textInput"
          className="form-control"
          style={{
            borderRadius: 10,
            borderWidth: 2,
            paddingRight: 52,
          }}
          placeholder={placeholder}
          rows="1"
          disabled={isLoading}
          value={msg}
          onChange={onChange}
        ></textarea>
        <div
          className="position-absolute top-50 start-100 translate-middle"
          style={{
            marginLeft: -42,
          }}
        >
          <Button disabled={isLoading || isLoadingSendAttachment} onClick={_onClick} color="link">
            {isLoading ? <Spinner size="sm" /> : <Send />}
          </Button>
        </div>
      </footer>
    </div>
  )
}
