import { useEffect, useState, useMemo } from "react"
import Select from "react-select"

import DataTable from "react-data-table-component"
import { Edit2, Trash2 } from "react-feather"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

import toast from "react-hot-toast"
import ModalDialog from "../../../shared/modals/ModalDialog"
import { paginationRowsPerPageOptions } from "../../../../utility/pagination.options"
import { useSkin } from "@src/utility/hooks/useSkin"
import {
  useDeleteAutoReplyMutation,
  useGetAllKeywordQuery,
  useGetAutoReplyQuery,
  usePatchAutoReplyMutation,
  usePostAutoReplyMutation,
} from "@src/redux/chat/chatHandle"
import { Controller, useForm } from "react-hook-form"

const columns = [
  {
    name: "#",
    selector: (row) => row.id,
    width: "6%",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.message,
    style: { whiteSpace: "unset" },
    sortable: true,
    width: "60%",
  },
  {
    name: "Auto Replay Keywords",
    selector: (row) => row.keywords,
    style: { whiteSpace: "unset" },
    sortable: true,
  },
  // {
  //   name: "Active",
  //   selector: (row) => row.actions,
  // },
  {
    name: "Modify",
    selector: (row) => row.modify,
  },
]

function AddModal({ onClose, onComplete }) {
  const { data: keywordData } = useGetAllKeywordQuery()
  const [postAutoReply] = usePostAutoReplyMutation()
  const { control, handleSubmit } = useForm()

  const keywordOption = useMemo(() => {
    return keywordData?.map((item) => ({
      value: item.id,
      label: item.key,
    }))
  }, [keywordData])

  const onSubmit = (data, e) => {
    e.preventDefault()
    const payload = {
      message: data.message,
      keywordIds: data.keywords.map((item) => item.value),
    }
    postAutoReply(payload)
      .then(() => {
        onComplete()
      })
      .catch((err) => {
        toast.error(`Failed save data:  ${err?.response?.data}`, { position: "top-center" })
      })
  }

  return (
    <ModalDialog open={true} setOpen={onClose} title="" disableCloseButton>
      <ModalHeader>Add Message</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Message</Label>
            <Controller
              name="message"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} required type="textarea" name="name" className="form-control" />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Keywords</Label>
            <Controller
              name="keywords"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Select isMulti {...field} options={keywordOption} />}
            />
          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>
    </ModalDialog>
  )
}

function UpdateModal({ open, setOpen, data, keywordData, onSuccess }) {
  // eslint-disable-next-line no-unused-vars
  const [patchAutoReply] = usePatchAutoReplyMutation()
  const { control, handleSubmit, setValue } = useForm()

  const keywordOption = useMemo(() => {
    return keywordData?.map((item) => ({
      value: item.id,
      label: item.key,
    }))
  }, [keywordData])

  const onSubmit = (formValues) => {
    const payload = {
      id: data.id,
      message: formValues.message,
      keywordIds: formValues.keywords.map((item) => item.value),
    }
    patchAutoReply(payload)
      .then((res) => {
        if (res.error) {
          return toast.error(`Failed save data:  ${res?.error?.error}`, { position: "top-center" })
        }
        toast.success(`Success save data`, { position: "top-center", duration: 5000 })
        onSuccess()
        setOpen(false)
      })
      .catch((err) => {
        toast.error(`Failed save data:  ${err?.response?.data}`, { position: "top-center" })
      })
  }

  useEffect(() => {
    if (data) {
      const keywords = data.autoReplyKeywords?.map((item) => ({
        value: item.keyword.id,
        label: item.keyword.key,
      }))
      setValue("message", data.message)
      setValue("keywords", keywords)
    }
  }, [data])

  return (
    <ModalDialog open={open} setOpen={setOpen} title="" disableCloseButton>
      <ModalHeader>Edit Message</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Message</Label>
            <Controller
              name="message"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input {...field} required type="textarea" name="name" className="form-control" />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>Keywords</Label>
            <Controller
              name="keywords"
              as={Select}
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Select isMulti {...field} options={keywordOption} />}
            />
          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>
    </ModalDialog>
  )
}

function DeleteModal({ open, setOpen, id, onSuccess }) {
  const [delCat] = useDeleteAutoReplyMutation()

  return (
    <ModalDialog
      open={open}
      setOpen={setOpen}
      title="Delete"
      onClose={() => {
        delCat({ id }).then((res) => {
          if (res.error) {
            return toast.error(`Failed delete data:  ${res?.error?.error}`, {
              position: "top-center",
            })
          }
          toast.success(`Success delete data`, { position: "top-center", duration: 5000 })
          onSuccess()
          setOpen(false)
        })
      }}
      closeText="Delete"
      buttonColor="danger"
    >
      Are you sure to delete this message?
    </ModalDialog>
  )
}

function ModifyButtonGroup({ data, onSuccess, keywordData }) {
  const [upOpen, setUpOpen] = useState(false)
  const [delOpen, setDelOpen] = useState(false)

  const edit = () => {
    setUpOpen(true)
  }

  const deleteCat = () => {
    setDelOpen(true)
  }
  return (
    <>
      <DeleteModal id={data.id} open={delOpen} setOpen={setDelOpen} onSuccess={onSuccess} />
      <UpdateModal
        keywordData={keywordData}
        data={data}
        open={upOpen}
        setOpen={setUpOpen}
        onSuccess={onSuccess}
      />
      <ButtonGroup>
        <UncontrolledTooltip placement="top" target={`edit-${data.id}`}>
          Edit
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon"
          color="primary"
          id={`edit-${data.id}`}
          onClick={() => edit()}
        >
          <Edit2 size={16} />
        </Button.Ripple>
        <UncontrolledTooltip placement="top" target={`delete-${data.id}`}>
          Delete
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon"
          color="danger"
          id={`delete-${data.id}`}
          onClick={() => deleteCat()}
        >
          <Trash2 size={16} />
        </Button.Ripple>
      </ButtonGroup>
    </>
  )
}

export default function AutoReply() {
  const { skin } = useSkin()

  const { data: keywordData } = useGetAllKeywordQuery()
  const [tableData, setTableData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState()
  const [page, setPage] = useState(1)

  const { data, isLoading, isError, refetch } = useGetAutoReplyQuery({ page, limit })

  const handleComplete = () => {
    setDialogOpen(false)
    refetch()
    toast.success(`Success save data`, { position: "top-center", duration: 5000 })
  }

  useEffect(() => {
    if (data) {
      setTotalPage(data.pagination.totalPage)
    }
    if (data?.data) {
      const row = []
      Array.from(data?.data).forEach((item, index) => {
        row.push({
          id: index + 1,
          message: (
            <>
              <UncontrolledTooltip placement="top" target={`message-${index}`}>
                {item.message}
              </UncontrolledTooltip>
              <span id={`message-${index}`}>{item.message}</span>
            </>
          ),
          keywords: item.autoReplyKeywords?.map((keyword) => keyword.keyword.key).join(", "),
          // isActive: item.isActive ? "Active" : "Inactive",
          // actions: (
          //   <div className="d-flex ">
          //     <ActiveButton
          //       id={item.id}
          //       isActive={item.isActive}
          //       onSuccess={() => refetch()})}
          //     />
          //   </div>
          // ),
          modify: (
            <div className="d-flex ">
              <ModifyButtonGroup
                keywordData={keywordData}
                data={item}
                isActive={item.isActive}
                onSuccess={() => refetch()}
              />
            </div>
          ),
        })
      })
      setTableData(row)
    }
  }, [data])

  return (
    <>
      <Modal
        className="modal-sm"
        centered
        isOpen={dialogOpen}
        toggle={() => {
          setDialogOpen(!dialogOpen)
        }}
      >
        <AddModal onComplete={handleComplete} onClose={() => setDialogOpen(false)} />
      </Modal>
      <Card>
        <CardHeader>
          <Row sm={1} md={2} lg={2} className="w-100">
            <Col>
              <CardTitle>Auto Reply</CardTitle>
            </Col>
            <Col className="text-right">
              <Button
                type="button"
                color="outline-primary"
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                + New
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {isError ? (
            <>Error: {JSON.stringify(error)}</>
          ) : isLoading ? (
            <>...Loading</>
          ) : data ? (
            <DataTable
              theme={skin}
              columns={columns}
              data={tableData}
              responsive={true}
              pagination
              paginationRowsPerPageOptions={paginationRowsPerPageOptions}
              paginationServer
              onChangeRowsPerPage={(rows) => {
                setLimit(rows)
              }}
              onChangePage={(page) => {
                setPage(page)
              }}
              paginationTotalRows={totalPage}
              className={"text-center"}
            />
          ) : null}
        </CardBody>
      </Card>
    </>
  )
}
