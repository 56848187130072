import fb from "@src/assets/images/icons/fb.svg"
import ig from "@src/assets/images/icons/ig.svg"
import tiktok from "@src/assets/images/icons/tiktok.svg"

export default function FooterNavigation(basePath) {
  // const {t} = useTranslation()
  return [
    {
      id: 0,
      title: "Company",
      children: [
        {
          id: 1,
          title: "About Us",
          url: `${basePath}/about-us`,
        },
        {
          id: 2,
          title: "For Job Seeker",
          url: "/seeker",
        },
        {
          id: 3,
          title: "For Employers",
          url: "/employer",
        },
      ],
    },
    {
      id: 1,
      title: "Account",
      children: [
        {
          id: 1,
          title: "My Dashboard",
          url: `/seeker/dashboard`,
        },
        {
          id: 2,
          title: "Login",
          url: "/login",
        },
        {
          id: 3,
          title: "Sign Up",
          url: "/register-for",
        },
      ],
    },
    {
      id: 2,
      title: "Job Type",
      children: [
        {
          id: 1,
          title: "Par Time",
          url: `/jobs`,
        },
        {
          id: 2,
          title: "Full Time",
          url: "/jobs",
        },
        {
          id: 3,
          title: "Contract Work",
          url: "/jobs",
        },
      ],
    },
    {
      id: 3,
      title: "Follow Us",
      children: [
        {
          id: 1,
          title: <img src={fb} alt="Facebook" />,
          url: "https://facebook.com/Mahajob.indonesia.jepang",
        },
        {
          id: 2,
          title: <img src={tiktok} alt="TikTok" />,
          url: "https://tiktok.com/@mahajobdotcom",
        },
        {
          id: 3,
          title: <img src={ig} alt="Instagram" />,
          url: "https://instagram.com/maha.job.matching",
        },
      ],
    },
  ]
}
