import React from "react"
import icon1 from "@src/assets/images/work-process/register-seeker.svg"
import icon2 from "@src/assets/icons/chevron-right.svg"
import { useWindowDimensions } from "@src/utility/hooks/useWindowDimensions"
import { useGetJobStatsQuery } from "@src/redux/job/job_stats"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SingleCandidate from "./SingleCandidate"

const StatsV2 = () => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 1024
  const { data, isLoading } = useGetJobStatsQuery()
  const dataSeeker = 4000 + parseInt(data?.seekerStats || 0)

  const { t, i18n } = useTranslation()
  const isJapan = i18n.language === "jp"

  const navigate = useNavigate()
  return (
    <div className="section-full p-b30 twm-how-it-work-area mt-2">
      <div className="px-3">
        <div className={`stats-v2 ${isMobile ? "flex flex-col w-full gap-y-2 mt-10" : "gap-x-4"}`}>
          <SingleCandidate />
          <div className="flex flex-col w-full">
            <h2
              className={`mt-5 font-bold text-red text-center 
              ${isMobile && "text-24"} ${!isJapan ? "text-18" : "text-lg"}`}
            >
              {t("Professional total support for hiring foreigners for the first time")}
            </h2>
            <h2
              className={`font-bold text-red text-center ${isMobile && "text-24"} ${
                !isJapan ? "text-18" : "text-lg"
              }`}
            >
              {t("～Recruiting foreign personnel～")}
            </h2>
            <div className="flex flex-col mt-3">
              <h2 className={`text-center ${!isJapan ? "text-18" : "text-24"}`}>
                {t("☑Job posting support　Find the talent your company is looking for")}
              </h2>
              <h2 className={`text-center ${!isJapan ? "text-18" : "text-24"}`}>
                {t(
                  "☑Document processing support　Specialists will support you from document preparation to submission on your behalf."
                )}
              </h2>
            </div>
          </div>
          <div
            className={`bg-pink-500 gap-x-4 text-center mt-2 ${isMobile ? "w-full mt-10" : "w-25"} rounded-lg p-24`}
          >
            <img src={icon1} className="w-24 h-24" />
            <div className={""}>
              <div className="text-lg mt-1 text-white font-semibold">
                {isLoading ? "Counting . . ." : `${dataSeeker} +`}
              </div>
                <div className="text-md text-white">{t("Number of job seekers")}</div>
              <div
                onClick={() => {
                  navigate("/candidate")
                }}
                className={`flex items-center mt-2 gap-x-2 cursor-pointer ${isMobile ? "justify-end" : ""}`}
              >
                <div className={`${isMobile ? "text-sm text-right" : "text-md"} text-white`}>
                  {t("Find out more")}
                </div>
                <img src={icon2} alt="right-arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsV2
