import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

const ModalDialog = ({
  open = false,
  setOpen,
  onClose,
  title = "",
  children,
  disableCloseButton,
  closeText,
  buttonColor,
  fullscreen = false,
  size = "lg",
  onCloseModal,
}) => {
  const toggle = () => {
    setOpen(false)
  }

  return (
    <Modal
      isOpen={open}
      toggle={() => setOpen(!open)}
      className="modal-dialog-centered"
      size={size}
      fullscreen={fullscreen}
      onClosed={() => {
        toggle()
        onCloseModal?.()
      }}
    >
      <ModalHeader color="white" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {disableCloseButton ? null : (
        <ModalFooter>
          <Button color={buttonColor ?? "primary"} onClick={onClose}>
            {closeText ?? "Oke"}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalDialog
