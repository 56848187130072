import {
  Cloud,
  FileText,
  Home,
  Layers,
  Mail,
  MessageSquare,
  Package,
  Settings,
  Tag,
  Type,
  CreditCard,
  List,
  Paperclip,
  MessageCircle,
  Key,
} from "react-feather"

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/dashboard/home",
  },
  // {
  //   id: "analytics",
  //   title: "Analytics",
  //   icon: <BarChart2 size={12} />,
  //   navLink: "/dashboard/analytics"
  // },
  {
    id: "subscribe",
    title: "Subscribe",
    icon: <CreditCard />,
    children: [
      {
        id: "subscriptionList",
        title: "Subscription List",
        icon: <List />,
        navLink: "/dashboard/subscriber",
      },
      {
        id: "subscriptionPackage",
        title: "Subscription Package",
        icon: <CreditCard />,
        navLink: "/dashboard/subscribe/package",
      },
    ],
  },
  // {
  //   id: "subscribe",
  //   title: "Subscribe",
  //   icon: <CreditCard />,
  //   navLink: "/dashboard/subscribe"
  // },
  {
    id: "administrator",
    title: "Administrator",
    icon: <Cloud size={20} />,
    navLink: "/dashboard/administrator",
  },
  {
    id: "employer",
    title: "Employer",
    icon: <Mail size={20} />,
    navLink: "/dashboard/employer",
  },
  {
    id: "jobSeeker",
    title: "Job Seeker",
    icon: <MessageSquare size={20} />,
    navLink: "/dashboard/job-seeker",
  },
  {
    id: "employerJobPost",
    title: "Job Post",
    icon: <FileText size={20} />,
    navLink: "/dashboard/job-post",
  },
  {
    id: "jobApplication",
    title: "Job Application",
    icon: <Layers size={20} />,
    navLink: "/dashboard/job-application",
  },
  // {
  //   id: "jobInterview",
  //   title: "Job Interview",
  //   icon: <PhoneCall size={20} />,
  //   navLink: "/dashboard/job-interview"
  // },
  {
    id: "jobCategory",
    title: "Job Category",
    icon: <Tag size={20} />,
    navLink: "/dashboard/job-category",
  },
  {
    id: "keywords",
    title: "Keywords",
    icon: <Key size={20} />,
    navLink: "/dashboard/keywords",
  },
  {
    id: "autoReply",
    title: "Auto Replay",
    icon: <MessageCircle size={20} />,
    navLink: "/dashboard/auto-reply",
  },
  {
    id: "jobIndustry",
    title: "Job Industry",
    icon: <Package size={20} />,
    navLink: "/dashboard/job-industry",
  },
  {
    id: "jobSeekerCertification",
    title: "Job Seeker Certification",
    icon: <Paperclip size={20} />,
    navLink: "/dashboard/job-seeker-certification",
  },
  {
    id: "jobType",
    title: "Job Type",
    icon: <Type size={20} />,
    navLink: "/dashboard/job-type",
  },
  // {
  //   id: "hero",
  //   title: "Hero",
  //   icon: <Type />,
  //   navLink: "/dashboard/hero"
  // },
  // {
  //   id: "administrator",
  //   title: "Administator",
  //   icon: <Droplet />,
  //   navLink: "/dashboard/administrator"
  // },

  {
    id: "roleManagement",
    title: "Role Management",
    icon: <Settings />,
    navLink: "/dashboard/role-management",
  },

  // {
  //   id: "privilege",
  //   title: "Privilege",
  //   icon: <Feather />,
  //   navLink: "/dashboard/privilege"
  // }
  // {
  //   id: "home",
  //   title: "家",
  //   icon: <Home size={20} />,
  //   navLink: "/dashboard/home"
  // },
  // {
  //   id: "secondPage",
  //   title: "2番目のページ",
  //   icon: <Mail size={20} />,
  //   navLink: "/dashboard/second-page"
  // },
  // {
  //   id: "roleManagement",
  //   title: "ロール管理",
  //   icon: <Settings />,
  //   navLink: "/dashboard/role-management"
  // }
]
